<template>
  <div class="AboutTrackMan">
    <div class="homepage">
      <h2 class="Whitetitle">{{acf.acf.trackman_title}}</h2>
      <p class="WhiteStandard-text aboutTrack-width" v-html="acf.acf.trackman_blog"></p>
      <b-row class="container optionAbout">
        <b-col class="above1200" lg="3" v-for="(option, index) in acf.acf.trackman_options" :key="index">
            <div class="borderWhite">
                <div class="aboutBlocks">
                    <div class="ImageLogoSection">
                        <img :src="option.option_image" class="ImageLogo" :class="option.option_class">
                    </div>
                    <h2 class="Whitetitle">{{option.option_title}}</h2>
                    <p class="aboutBlock-Text" v-html="option.option_blog">
                    </p>
                    <div class="triangle-right"></div>
                </div>
            </div>
        </b-col>
        <b-col class="below1200" lg="6" v-for="(option, index) in acf.acf.trackman_options" :key="index">
            <div class="borderWhite">
                <div class="aboutBlocks">
                    <div class="ImageLogoSection">
                        <img :src="option.option_image" class="ImageLogo" :class="option.option_class">
                    </div>
                    <h2 class="Whitetitle">{{option.option_title}}</h2>
                    <p class="aboutBlock-Text" v-html="option.option_blog">
                    </p>
                    <div class="triangle-right"></div>
                </div>
            </div>
        </b-col>
        <!-- <b-col lg="3">
            <div class="borderWhite">
                <div class="aboutBlocks">
                    <div class="ImageLogoSection">
                        <img src="https://wp-ocssport.ocs-sport.com/golfpark/wp-content/uploads/sites/4/2024/06/Golf-Sim.png" class="ImageLogo">
                    </div>
                    <h2 class="Whitetitle">Golf Practise</h2>
                    <p class="aboutBlock-Text">
                        Practise your swing and improve your game in the top of the range Golf simulator.
                    </p>
                    <div class="triangle-right"></div>
                </div>
            </div>
        </b-col>
        <b-col lg="3">
            <div class="borderWhite">
                <div class="aboutBlocks">
                    <div class="ImageLogoSection">
                        <img src="https://wp-ocssport.ocs-sport.com/golfpark/wp-content/uploads/sites/4/2024/06/Group-64.png" class="ImageLogo">
                    </div>
                    <h2 class="Whitetitle">Putting Green</h2>
                    <p class="aboutBlock-Text">
                        Hone your skills on our outside 9 hole putting green.
                    </p>
                    <div class="triangle-right"></div>
                </div>
            </div>
        </b-col>
        <b-col lg="3">
            <div class="borderWhite">
                <div class="aboutBlocks">
                    <div class="ImageLogoSection">
                        <img src="https://wp-ocssport.ocs-sport.com/golfpark/wp-content/uploads/sites/4/2024/06/Group-49.png" class="ImageLogo coachingLogo">
                    </div>
                    <h2 class="Whitetitle">Golf Coaching</h2>
                    <p class="aboutBlock-Text">
                        We’ve partnered with top coaches to offer lessons and instruction, including swing evaluation and beginner lessons.
                    </p>
                    <div class="triangle-right"></div>
                </div>
            </div>
        </b-col>
        <b-col lg="3">
            <div class="borderWhite">
                <div class="aboutBlocks">
                    <div class="ImageLogoSection">
                        <img src="https://wp-ocssport.ocs-sport.com/golfpark/wp-content/uploads/sites/4/2024/06/Path-83.png" class="ImageLogo exclusive-Club">
                    </div>
                    <h2 class="Whitetitle">Exclusive Club</h2>
                    <p class="aboutBlock-Text">
                        Golfpark is an exclusive club allowing members private bookings.
                    </p>
                    <div class="triangle-right"></div>
                </div>
            </div>
        </b-col> -->
      </b-row>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'About Trackman',
  components: {

  },
  data: function() {
    return {
      acf: [],
    };
  },
  methods: {
    isMobile() {
        if( screen.width <= 500 ) {
            return true;
        }
        else {
            return false;
        }
    },
  },
  mounted() {
    axios //add API Call
        .get(
            process.env.VUE_APP_API_URL + 'racepark' + "/wp-json/wp/v2/pages/4" + '?randomadd=' +
          new Date().getTime()
        ) // Page number changed on each page based on JSON page id you are calling
        .then((response) => (
            this.acf = response.data
        )); //API data wanted pulled out
  },
}
</script>

<style scoped>
img.ImageLogo.Car {
    width: 120px;
    height: auto;
    margin-top: 41px;
}
.optionAbout {
    margin: auto;
    /* margin-right: 85px */
}
.Whitetitle {
    color: #fff;
    font-size: 40px;
    font-family: "korolev", sans-serif;
    font-weight: 700;
    font-style: italic;
}
.AboutTrackMan {
    background-color: #1A1A1A;
    padding: 50px;
    padding-top: 90px;
    padding-bottom: 90px;
}
.WhiteStandard-text {
    font-size: 24px;
    color: #fff;
    font-family: "noto-sans", sans-serif;
    font-weight: 400;
    font-style: normal;
}
.aboutTrack-width {
    width: 70%;
    text-align: center;
    margin: auto;
    padding-top: 30px;
    padding-bottom: 70px;
}
.borderWhite {
    border: 1px solid #FFFFFF;
}
.ImageLogo {
    height: 70px;
    width: 70px;
}
.coachingLogo {
    width: 50px;
}
.exclusive-Club {
    height: 50px;
    position: relative;
    top: 20px;
    margin-bottom: 20px;
}
.aboutBlocks {
    padding: 30px;
    height: 580px;
}
p.aboutBlock-Text {
    color: #fff;
    font-size: 20px;
    padding-top: 30px;
    font-family: "noto-sans", sans-serif;
    font-weight: 400;
    font-style: normal;
}
.triangle-right {
    width: 0;
    height: 0;
    border-top: 60px solid transparent;
    border-left: 61px solid #D80E1F;
    border-bottom: 0px solid transparent;
    transform: rotate(270deg);
    position: absolute;
    right: 16px;
    bottom: 2.1px;
}
.ImageLogoSection {
    padding-top: 40px;
    padding-bottom: 50px;
}
@media only screen and (max-width: 1366px) {
    .aboutBlocks {
        padding: 30px;
        height: 600px;
    }
}
@media only screen and (min-width: 1201px) {
    .below1200 {
        display: none;
    }
}
@media only screen and (max-width: 1200px) {
    .Whitetitle {
        color: #fff;
        font-size: 35px;
        font-family: "korolev", sans-serif;
        font-weight: 700;
        font-style: italic;
    }
    .above1200 {
        display: none;
    }
    .aboutBlocks {
        padding: 30px;
        height: 535px;
    }
    .below1200.col-lg-6 {
        margin-bottom: 30px;
    }
}
@media only screen and (max-width: 768px) {
    img.ImageLogo.Car {
        width: 120px;
        height: auto;
    }
    .triangle-right {
        bottom: 27.1px;
    }
    .borderWhite {
        margin-bottom: 25px;
    }
    .ImageLogoSection {
        padding: 0;
    }
    .aboutBlocks {
        padding: 30px;
        height: 500px;
    }
    .AboutTrackMan {
        padding: 25px;
    }
    .container {
        padding-left: 0;
        padding-right: 0;
    }
    .aboutTrack-width {
        width: 100%;
        text-align: center;
        margin: auto;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .optionAbout {
        margin: auto;
        margin-right: 0px
    }
    .Whitetitle {
        font-size: 36px;
        margin-top: 30px;
    }
    .WhiteStandard-text{
        font-size: 20px;
    }
}
</style>
